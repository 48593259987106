import React from 'react';
import Turbolinks from 'turbolinks';

import PlatformHexes from 'components/PlatformHexes';

export default function LandingPage() {
  function onHexagonClick(platform) {
    Turbolinks.visit(`/sign_up/${platform.linkName}${window.location.search}`);
  }

  return (
    <div className="landing-content">
      <section className="flex-col flex-align-center">
        <h1 className="mb-5">Welcome to BindPlane!</h1>
        <p className="text-large text-center mb-0">
          Where do you want us to send your metrics?
        </p>

        <div className="platform-hexes-container">
          <PlatformHexes onClick={onHexagonClick} />
        </div>
        <p className="low-intensity mt-5 mb-0">
          Already have an account?&nbsp;
          <a className="text-link" href="/sign_in">
            Sign in
          </a>
          .
        </p>
      </section>
    </div>
  );
}
