/**
 * Things to help building CSS-in-JS
 */

import {
  darken as darkenP,
  lighten as lightenP,
  rgba as rgbaP,
  transparentize as transparentizeP,
} from 'polished';

// eslint-disable-next-line
const importedColors = require('sass-extract-loader?{"plugins":["sass-extract-js"]}!../../../stylesheets/colors.scss');
const getter = (target, key) => {
  const color = target[key];
  if (color.startsWith("'brightness(0)'")) {
    const formatted = target[key].replace(/', '/g, ' ');
    return formatted.substring(1, formatted.length - 1);
  }
  if (_.isNil(color)) {
    throw new Error(`Undefined color: ${key}`);
  }
  return color;
};

const colors = new Proxy(importedColors, { get: getter });

const theme = { colors };

// Swapped the default `polished` arguments order to make them look SASSy
const darken = (arg1, arg2) => darkenP(arg2, arg1);
const lighten = (arg1, arg2) => lightenP(arg2, arg1);
const transparentize = (arg1, arg2) => transparentizeP(arg2, arg1);
const rgba = (a, b, c, d) => rgbaP(a, b, c, d);

export { darken, lighten, rgba, theme, transparentize };
export * from './classes';
