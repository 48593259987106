// eslint-disable-next-line
export function getChartColorForPlatform(name) {
  switch (name) {
    case 'datadog':
      return '#7749A4';
    case 'stackdriver':
    case 'google_stackdriver':
    case 'google_cloud_monitoring':
      return '#2A663C';
    case 'microsoft_azure_monitor':
      return '#2D7CD0';
    case 'new_relic':
      return '#308597';
    case 'opsdatastore':
      return '#0089A3';
    case 'sap_itoa':
      return '#4BA9E1';
    case 'servicenow':
      return '#8BB5A4';
    case 'servicenow_cmdb':
      return '#8BB5A4';
    case 'signalfx':
      return '#59A200';
    case 'splunk':
      return '#94B966';
    case 'sumo_logic':
      return '#1194D2';
    case 'vmware_vrealize_operations_manager':
      return '#1194D2';
    case 'wavefront':
      return '#28ACE2';
    default:
      return null;
  }
}

export function platform_class(name) {
  return name.toLowerCase().replace(/\s/g, '_');
}

export const Platform = {
  AZURE_METRICS: '51401994-bf43-4852-ae7f-c422ad15a9c5',
  DATADOG: '7bfcf25c-d528-4496-b62d-3d26399df13b',
  INFLUX_DB: 'f815054d-6ee8-4658-884d-6dc6f6f144f8',
  NEW_RELIC: '87e42437-bebd-40ce-886a-bf7e17b584e9',
  NUTANIX: 'eb64859d-73ad-4e61-bdd8-60cf0c5732c7',
  OMS: '07b83f1b-da13-4634-ae19-63e7fb4edf93',
  SAP_ITOA: '5d474c26-1104-43d9-b584-b31c52d1f849',
  SERVICE_NOW: 'aa321173-1f13-4477-88c8-5fd62967fff4',
  SIGNALFX: '05cdb14e-b4a3-4ef3-a010-699ffa832e0e',
  SPLUNK: '385ae862-e695-470a-a8a7-59db9e40499b',
  STACKDRIVER_V3: '06802251-c79f-44e0-9460-178db3616594',
  STACKDRIVER_V4: '2248f9d6-0807-4708-b2e8-f8bb9872be14',
  SUMOLOGIC: 'aec504ec-8cc9-423d-9ed2-d2bd5e841767',
  VR_OPS: 'a3b6258f-2472-45cd-b499-6741bd7aad60',
  WAVEFRONT: 'a7cdc881-b1c5-4058-bec1-d7aa8fe281c7',
};
