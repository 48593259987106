import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'react-emotion';
import { theme } from 'utils/styles';

// import useDomRect from '../../hooks/useDomRect'
import SVGText from './SVGText';

export const blockStyle = css`
  fill: ${theme.colors.white};
  filter: url(#block-basic-shadow);
  cursor: pointer;
`;

const disabledImage = props =>
  props.disabled &&
  css`
    filter: url(#grayscale);
  `;

export const Image = styled.image`
  ${disabledImage}
`;

export function Group({ children, x, y, ...rest }) {
  const ref = useRef();
  // const rect = useDomRect(ref)

  return (
    <g ref={ref} transform={`translate(${x}, ${y})`} {...rest}>
      {/* <rect fill="none" height={rect.height} stroke="red" width={rect.width} x={0} y={0} /> */}
      {children}
    </g>
  );
}
Group.propTypes = {
  children: PropTypes.node.isRequired,
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
};

export function NoDataPlaceholder({ image, text, transform }) {
  return (
    <NoDataPlaceholderContainer transform={transform}>
      {!_.isEmpty(text) && (
        <SVGText className={labelClass} width={130} x={65} y={0}>
          {text}
        </SVGText>
      )}

      <image {...image} />
    </NoDataPlaceholderContainer>
  );
}

NoDataPlaceholder.propTypes = {
  image: PropTypes.shape({
    height: PropTypes.number.isRequired,
    width: PropTypes.number.isRequired,
    x: PropTypes.number.isRequired,
    xlinkHref: PropTypes.string.isRequired,
    y: PropTypes.number.isRequired,
  }).isRequired,
  text: PropTypes.string,
  transform: PropTypes.string.isRequired,
};
NoDataPlaceholder.defaultProps = {
  text: '',
};

const NoDataPlaceholderContainer = styled.g`
  cursor: default;
`;

const labelClass = css`
  fill: ${theme.colors.darkGray};
  font-weight: 400;
  text-anchor: middle;
`;
