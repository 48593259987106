import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import IScroll from 'iscroll';
import styled, { css } from 'react-emotion';

/**
 * `<defs>` is a special tag which depicts commonly used styles.
 * https://developer.mozilla.org/en-US/docs/Web/SVG/Element/defs
 * We need it for cross-browser CSS `filter: drop-shadow` replacement
 */
function renderDefs() {
  return (
    <defs>
      <filter id="block-basic-shadow">
        <feDropShadow
          dx="0"
          dy="1"
          floodColor="rgba(0,0,0,0.47)"
          floodOpacity="1"
          stdDeviation="2"
        />
      </filter>

      <filter id="block-bold-shadow">
        <feDropShadow
          dx="0"
          dy="0"
          floodColor="rgba(0,0,0,0.33)"
          floodOpacity="1"
          stdDeviation="5"
        />
      </filter>

      <filter id="grayscale">
        <feColorMatrix
          type="matrix"
          values="0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0"
        />
      </filter>

      <linearGradient id="white-gradient" x1="0" x2="0" y1="0" y2="1">
        <stop offset="0%" stopColor="white" stopOpacity="0" />
        <stop offset="40%" stopColor="white" stopOpacity="0.7" />
        <stop offset="90%" stopColor="white" />
        <stop offset="100%" stopColor="white" />
      </linearGradient>
    </defs>
  );
}

export default function SVGPane({ children, containerClass, height, width, ...rest }) {
  const iscroll = useRef();
  const [isScrolling, setIsScrolling] = useState(false);
  const containerClassSelector = `.${containerClass}`;

  /**
   * Bringing IScroll for wide figures
   */
  function makeSwipable() {
    iscroll.current = new IScroll(containerClassSelector, {
      keyBindings: {
        left: 37,
        up: 38,
        right: 39,
        down: 40,
      },
      mouseWheel: true,
      scrollbars: true,
      scrollX: true,
      scrollY: true,
      tap: 'iscrollTap',
    });

    // Center the figure
    const container = document.querySelector(containerClassSelector);
    const scrollTo = (container.scrollWidth - container.offsetWidth) / 2;
    iscroll.current.scrollTo(-1 * scrollTo, 0); // `-1` coefficient because of (0, 0) it left top corner

    iscroll.current.on('beforeScrollStart', () => setIsScrolling(true));
    iscroll.current.on('scrollEnd', () => setIsScrolling(false));
    iscroll.current.on('scrollCancel', () => setIsScrolling(false));
  }

  // On initialization
  useLayoutEffect(() => {
    makeSwipable();
  }, []);

  useEffect(() => {
    iscroll.current.refresh();
  }, [height, width]);

  return (
    <SVG
      grabbing={isScrolling}
      height={height}
      width={width}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...rest}
    >
      {renderDefs()}
      {children}
    </SVG>
  );
}
SVGPane.propTypes = {
  children: PropTypes.node.isRequired,
  containerClass: PropTypes.string,
  height: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
};
SVGPane.defaultProps = {
  containerClass: 'overview-container',
};

const grabbing = props =>
  props.grabbing &&
  css`
    cursor: grabbing;
  `;

const SVG = styled.svg`
  display: block;
  margin: ${props => (props.centered ? '0 auto' : '0')};
  cursor: grab;

  ${grabbing}
`;
