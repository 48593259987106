import React from 'react';
import PropTypes from 'prop-types';

/**
 * Commonly used text patterns across all SVG doc
 * Adds ellipsis if text width is overflowing
 */
class SVGText extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      fillOpacity: 0,
      displayedText: props.children,
      showEllipsis: false,
    };
  }

  componentDidMount() {
    if (this.props.ellipsed) {
      this.slimText();
    }

    this.setState({ fillOpacity: 1 });
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(this.props.children, prevProps.children)) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ displayedText: this.props.children }, () => {
        this.slimText();
      });
    }
  }

  /**
   * Recursion function. Working horse of the component.
   * It reduces width by removing one last character until width fits
   * May lead to some performance issues, because of one rerendering per one character shortening
   */
  slimText() {
    const { width } = this.props;
    const { displayedText } = this.state;

    if (width < this.textElement.getComputedTextLength()) {
      this.setState(
        {
          displayedText: displayedText.slice(0, -1),
          showEllipsis: true,
        },
        this.slimText,
      );
      return;
    }

    this.setState({
      fillOpacity: 1,
    });
  }

  render() {
    // eslint-disable-next-line no-unused-vars
    const { x, y, className, ellipsed, ...rest } = this.props;
    const { displayedText, fillOpacity, showEllipsis } = this.state;

    return (
      <text
        ref={textElement => {
          this.textElement = textElement;
        }}
        className={className}
        fillOpacity={fillOpacity}
        x={x}
        y={y}
        {...rest}
      >
        {displayedText}
        {showEllipsis ? '...' : ''}
      </text>
    );
  }
}

SVGText.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  ellipsed: PropTypes.bool,
  width: PropTypes.number.isRequired,
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
};
SVGText.defaultProps = {
  className: '',
  ellipsed: true,
};

export default SVGText;
