const validEmailRegex = new RegExp(/.+@.+/);
const pwLengthRegex = new RegExp(/[A-z0-9!$@%*_?&-]{6,}/);
const pwCaseRegex = new RegExp(/(?=.*[a-z])(?=.*[A-Z]).*/);
const pwNumberRegex = new RegExp(/(?=.*[0-9]).*/);
const pwSymbolRegex = new RegExp(/(?=.*[!$@%*_?&-]).*/);

const validations = {
  'company[name]': function (val) {
    if (_.isEmpty(val)) return 'Company name cannot be blank';
    return undefined;
  },
  'user[email]': function (val) {
    if (_.isEmpty(val)) return 'Email cannot be blank';
    if (!validEmailRegex.test(val)) return 'Email appears to be invalid';

    return undefined;
  },
  'user[password]': function (val) {
    if (!pwLengthRegex.test(val)) return '';
    if (!pwCaseRegex.test(val)) return '';
    if (!pwNumberRegex.test(val)) return '';
    if (!pwSymbolRegex.test(val)) return '';

    return undefined;
  },
  'user[eula]': function (val) {
    if (!val) return 'Master Subscription Agreement must be accepted';
    return undefined;
  },
};

$(document).on('turbolinks:load', () => {
  // Only do this on /sign_up, not on /sign_in or elsewhere
  // since the field IDs are the same
  if (window.location.pathname.indexOf('/sign_up') < 0) return;

  const companyNameInput = $('#company_name');
  const emailInput = $('#user_email');
  const passwordInput = $('#user_password');
  const eulaInput = $('#user_eula');
  const submitButton = $('#signup');
  const form = $('#new_user');

  const ruleElements = [
    {
      element: $('#password-rules #length'),
      regex: pwLengthRegex,
    },
    {
      element: $('#password-rules #letters'),
      regex: pwCaseRegex,
    },
    {
      element: $('#password-rules #number'),
      regex: pwNumberRegex,
    },
    {
      element: $('#password-rules #special-character'),
      regex: pwSymbolRegex,
    },
  ];

  function clearFieldError(element) {
    element.removeClass('form-control-error');
  }

  function setFieldError(element, error) {
    element.addClass('form-control-error');
    const el = $('<div>', { class: 'form-control-feedback' }).text(error);
    element.parent().append(el);
  }

  function validateField(el) {
    const value = el.val();
    const name = el.attr('name');

    const validationResult = validations[name](value);
    const isValid = _.isNil(validationResult);

    setTimeout(() => {
      // Remove the current errors, if they exist. If validation fails, then
      // the error will be replaced with the new error message.
      const feedback = el.siblings('.form-control-feedback').first();

      // If we're clicking on a link, no longer validate cause we're going away
      if (form.data('clicking')) return;

      if (feedback) feedback.remove();
      if (isValid) {
        clearFieldError(el);
      } else {
        if (!el.data('blurred')) return;
        setFieldError(el, validationResult);
      }
      validateForm();
    }, 100);
  }

  function validatePasswordRules(e) {
    const value = e.target.value;

    const passedRules = [];
    ruleElements.forEach(({ element, regex }) => {
      const passed = regex.test(value);
      element.find('.check').toggleClass('hidden', !passed);
      element.find('.fail').toggleClass('hidden', passed);
      passedRules.push(passed);
    });

    if (!passwordInput.data('blurred')) return;
    const allPassed = !passedRules.some(v => v === false);
    $('#user_password').toggleClass('form-control-error', !allPassed);
  }

  function validateForm() {
    const validities = [companyNameInput, emailInput, passwordInput, eulaInput].map(
      field => {
        const name = field.prop('name');
        if (validations[name]) {
          if (field.prop('type') === 'checkbox') {
            return _.isNil(validations[name](field.prop('checked')));
          }
          return _.isNil(validations[name](field.val()));
        }

        return true;
      },
    );

    const isValid = !validities.some(v => v === false);
    submitButton.prop('disabled', !isValid);
  }
  const basicFields = [companyNameInput, emailInput, passwordInput];

  basicFields.forEach(field => field.on('change keyup', () => validateField(field)));

  // We don't want to validate the field until they leave it, so we don't show
  // them an error as they're typing their email for the first time
  basicFields.forEach(field =>
    field.on('blur', () => {
      field.data('blurred', true);
      validateField(field);
    }),
  );

  // special-case the eula field - the error div is outside the .field div,
  // so we have to remove/add it specially.
  eulaInput.on('change blur', () => {
    const value = eulaInput.prop('checked');
    const name = eulaInput.attr('name');

    const validationResult = validations[name](value);
    const isValid = _.isNil(validationResult);

    const feedback = $('.form-control-feedback.form-control-inline-error');
    if (feedback) feedback.remove();

    if (!isValid) {
      const el = $('<div>', {
        class: 'form-control-feedback form-control-inline-error',
      }).text(validationResult);
      eulaInput.parent().after(el);
    }

    validateForm();
  });

  // The password rules should be hidden until typed into, but then should stay.
  // Otherwise it looks janky adding/removing elements on field focus
  passwordInput.on('change keyup blur', e => {
    setTimeout(() => {
      // If we're clicking on a link, no longer validate cause we're going away
      if (form.data('clicking')) return;
    }, 100);

    validatePasswordRules(e);
  });

  $('a').click(() => {
    form.data('clicking', true);
  });

  $('#new_user').submit(() => {
    if (window.dataLayer) {
      // eslint-disable-next-line quote-props
      window.dataLayer.push({ event: 'new-signup', signup_type: 'regular' });
    }

    return true;
  });
});
