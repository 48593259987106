import Alerts from 'utils/alerts';

function disable_all_toggles() {
  $('.js-toggle').attr('disabled', true);
}

$(document).on('click', '.js-toggle', function (e) {
  e.stopPropagation();
  e.preventDefault();

  const toggle = $(this);
  const input = toggle.prev('input');
  const row = input.closest('tr');

  if (toggle.attr('disabled')) return;

  const parentRow = $(`tr.filter-metric-group[data-key="${row.data('parent-key')}"]`);
  let shouldToggleParent = false;
  if (!_.isEmpty(row.data('parent-key'))) {
    const siblings = row.siblings(
      `tr.filter-submetric[data-parent-key="${row.data('parent-key')}"]`,
    );
    const siblingToggles = siblings.find('input').map(function () {
      return $(this).is(':checked');
    });

    shouldToggleParent = Array(...siblingToggles).every(v => v === false);
  }

  let url;
  if (input.is(':checked')) {
    input.prop('checked', false);
    row.toggleClass(toggle.data('class-disabled'), true);
    if (shouldToggleParent) {
      parentRow.toggleClass(toggle.data('class-disabled'), true);
    }

    url = toggle.data('off-href');
  } else {
    input.prop('checked', true);
    row.toggleClass(toggle.data('class-disabled'), false);
    if (shouldToggleParent) {
      parentRow.toggleClass(toggle.data('class-disabled'), false);
    }

    url = toggle.data('on-href');
  }

  $.ajax({
    type: 'POST',
    url,
    success() {
      if (toggle.data('reload')) {
        disable_all_toggles();
        window.location.reload(true);
      }
    },
    error() {
      Alerts.error('Unable to toggle the setting');
    },
  });
});
