import { css } from 'react-emotion';

const ellipsis = css`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const flexRow = css`
  display: flex;
  flex-direction: row;
`;

const flexCol = css`
  display: flex;
  flex-direction: column;
`;

const breakWords = css`
  overflow-wrap: break-word;
  word-wrap: break-word;
`;

const unstyledButton = css`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border: none;
  cursor: pointer;

  &[type='button'] {
    -webkit-appearance: none;
    appearance: none;
  }
`;
const editIcon = css`
  ${unstyledButton}
  position: absolute;
  top: calc(50% - 14px);
  right: 4px;
  margin-right: 0;

  &:hover {
    cursor: pointer;
  }

  img {
    width: 16px;
    height: 16px;
  }
`;

// eslint-disable-next-line import/prefer-default-export
export const classes = {
  breakWords,
  editIcon,
  ellipsis,
  flexRow,
  flexCol,
  unstyledButton,
};
