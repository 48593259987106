// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import { initialize } from '../src/javascript/initialize_pack';
import '../src/javascript/login/pack';
import '../src/stylesheets/application.scss';

initialize(require.context('src/javascript/login', true));
