document.addEventListener('turbolinks:load', () => {
  if (window.Sortable) window.Sortable.init();

  // Monkey patch Turbolinks to render 403, 404 & 500 normally.
  // Without this, Turbolinks encounters an error and bails rendering any JS, causing react components
  // not to render - in this case, the navigation menu on error pages.
  // See https://github.com/turbolinks/turbolinks/issues/179
  window.Turbolinks.HttpRequest.prototype.requestLoaded = function () {
    return this.endRequest(() => {
      const code = this.xhr.status;
      if ((code >= 200 && code < 300) || code === 403 || code === 404 || code === 500) {
        this.delegate.requestCompletedWithResponse(
          this.xhr.responseText,
          this.xhr.getResponseHeader('Turbolinks-Location'),
        );
      } else {
        this.failed = true;
        this.delegate.requestFailedWithStatusCode(code, this.xhr.responseText);
      }
    });
  };
});
