import Alerts from 'utils/alerts';

$(document).on('turbolinks:load', () => {
  $('#flash .info').each(function () {
    const text = $(this).text();
    Alerts.success(text);
    $(this).remove();
  });

  $('#flash .notice').each(function () {
    const text = $(this).text();
    Alerts.success(text);
    $(this).remove();
  });

  $('#flash .alert').each(function () {
    const text = $(this).text();
    Alerts.error(text);
    $(this).remove();
  });
});
