import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { css } from 'react-emotion';
import { getPositionForPlatform } from 'utils/overview';
import { Platform } from 'utils/platforms';

import Tooltip, { TooltipHeader } from './SVGPane/Tooltip';
import SVGPane from './SVGPane';
import { LargeHexagon } from './SVGPane/shapes';

const platforms = [
  {
    id: Platform.NEW_RELIC,
    label: 'New Relic',
    linkName: 'new_relic',
    name: 'new_relic',
    tooltipWidth: 166,
    x: 4,
    y: 4,
  },
  {
    id: Platform.STACKDRIVER_V4,
    label: 'Google Cloud Monitoring',
    linkName: 'google',
    name: 'google_cloud_monitoring',
    tooltipWidth: 282,
    x: 104,
    y: 64,
  },
  {
    id: Platform.DATADOG,
    label: 'Datadog',
    linkName: 'datadog',
    name: 'datadog',
    tooltipWidth: 156,
    x: 4,
    y: 124,
  },
  {
    id: Platform.OMS,
    label: 'Microsoft Azure',
    linkName: 'azure',
    name: 'microsoft_azure_monitor',
    tooltipWidth: 210,
    x: 204,
    y: 4,
  },
  {
    id: Platform.WAVEFRONT,
    label: 'Wavefront',
    linkName: 'wavefront',
    name: 'wavefront',
    tooltipWidth: 172,
    x: 204,
    y: 124,
  },
];

const SCALE = 1.3;

export default function PlatformHexes({ onClick }) {
  const [hoveredPlatform, setHoveredPlatform] = useState(null);
  const [selectedPlatform, setSelectedPlatform] = useState(null);

  function onHover(platform) {
    setHoveredPlatform(platform);
  }

  function onClickHexagon(platform) {
    setSelectedPlatform(platform);
    onClick(platform);
  }

  function renderPlatformHexagon(platform) {
    const position = getPositionForPlatform(platform.name);
    const isHexagonActive =
      _.get(selectedPlatform, 'name', '') === platform.name ||
      _.get(hoveredPlatform, 'name', '') === platform.name;

    if (onClick) {
      return (
        <g
          key={platform.name}
          className={buttonStyle}
          id={`landing-link-${platform.name}`}
          onBlur={() => onHover(null)}
          onClick={() => onClickHexagon(platform)}
          onFocus={() => onHover(platform)}
          onMouseLeave={() => onHover(null)}
          onMouseOver={() => onHover(platform)}
          transform={`translate(${platform.x}, ${platform.y})`}
        >
          <LargeHexagon active={isHexagonActive} />
          <image
            xlinkHref={`/assets/platform-icons/large/${platform.name}@2x.png`}
            {...position}
          />
        </g>
      );
    }

    return (
      <a
        key={platform.name}
        className={linkStyle}
        href={`/sign_up/${platform.linkName}${window.location.search}`}
        id={`landing-link-${platform.name}`}
        onBlur={() => {
          onHover(null);
        }}
        onFocus={() => {
          onHover(platform);
        }}
        onMouseLeave={() => {
          onHover(null);
        }}
        onMouseOver={() => {
          onHover(platform);
        }}
        transform={`translate(${platform.x}, ${platform.y})`}
      >
        <LargeHexagon active={_.get(hoveredPlatform, 'name', '') === platform.name} />
        <image
          xlinkHref={`/assets/platform-icons/large/${platform.name}@2x.png`}
          {...position}
        />
      </a>
    );
  }

  function renderTooltip() {
    if (_.isNil(hoveredPlatform)) return null;

    return (
      <g
        transform={`translate(${(hoveredPlatform.x + 172) * SCALE}, ${
          (hoveredPlatform.y - 80) * SCALE
        })`}
      >
        <Tooltip
          arrow="bottom"
          height={40}
          transform="translate(30,110)"
          width={hoveredPlatform.tooltipWidth + 12}
        >
          <TooltipHeader width={hoveredPlatform.tooltipWidth} x={12} y={25}>
            {`Stream to ${hoveredPlatform.label}`}
          </TooltipHeader>
        </Tooltip>
      </g>
    );
  }

  return (
    <SVGPane
      centered={false}
      containerClass="platform-hexes-container"
      height={229 * SCALE + 40}
      width={316 * SCALE + 340}
    >
      <g transform={`scale(${SCALE}) translate(128,30)`}>
        {platforms.map(platform => renderPlatformHexagon(platform))}
      </g>
      {renderTooltip()}
    </SVGPane>
  );
}
PlatformHexes.propTypes = {
  onClick: PropTypes.func,
};
PlatformHexes.defaultProps = {
  onClick: null,
};

const buttonStyle = css`
  cursor: pointer;

  &:active {
    outline: none;
  }
`;

const linkStyle = css`
  &:active {
    outline: none;
  }
`;
