// eslint-disable-next-line import/prefer-default-export
export const getPositionForPlatform = name => {
  return _.get(PLATFORM_IMAGES, name, PLATFORM_IMAGES.DEFAULT);
};

export const PLATFORM_IMAGES = {
  microsoft_azure_monitor: {
    height: 75,
    width: 141,
    x: -15,
    y: 11,
  },
  servicenow: {
    height: 50,
    width: 94,
    x: 10,
    y: 22,
  },
  servicenow_cmdb: {
    height: 50,
    width: 94,
    x: 10,
    y: 22,
  },
  new_relic: {
    height: 75,
    width: 141,
    x: -13,
    y: 12,
  },
  google_stackdriver: {
    height: 70,
    width: 132,
    x: -10,
    y: 14,
  },
  stackdriver: {
    height: 70,
    width: 132,
    x: -10,
    y: 14,
  },
  google_cloud: {
    height: 70,
    width: 132,
    x: -10,
    y: 14,
  },
  google_cloud_monitoring: {
    height: 70,
    width: 132,
    x: -10,
    y: 14,
  },
  datadog: {
    height: 70,
    width: 132,
    x: -11,
    y: 14,
  },
  wavefront: {
    height: 80,
    width: 150,
    x: -17,
    y: 8,
  },
  // logs bundle_type is newrelic
  newrelic: {
    height: 75,
    width: 141,
    x: -13,
    y: 12,
  },
  bp_log_forwarder: {
    height: 85,
    width: 161,
    x: -21,
    y: 7,
  },
  DEFAULT: {
    height: 50,
    width: 94,
    x: 10,
    y: 22,
  },
};
