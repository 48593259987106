import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'react-emotion';
import { theme } from 'utils/styles';

import { blockStyle } from './common';

const PLATFORM_D = `
M 3 53.7
q -3 -5.2, 0 -10.4
l 22 -38.1
q 3 -5.2, 9 -5.2
l 44 0
q 6 0, 9 5.2
l 22 38.1
q 3 5.2, 0 10.4
l -22, 38.1
q -3, 5.2, -9, 5.2
l -44 0
q -6 0, -9 -5.2
Z
`;

const ENDPOINT_D = `
M 25 3
q 5 -3, 10 0
l 21 12
q 4 3, 4 8
l 0 25
q 0 4, -4 7
l -21 12
q -5 3, -10 0
l -21 -12
q -4 -3, -4 -7
l 0 -25
q 0 -5, 4 -8
Z
`;

const AGENT_D = `
M 3 36.7
q -3 -5.2, 0 -10.4
l 9 -15.6
q 3 -5.2, 9 -5.2
l 101 0
q 6 0, 9 5.2
l 9 15.6
q 3 5.2, 0 10.4
l -9, 15.6
q -3, 5.2, -9, 5.2
l -101 0
q -6 0, -9 -5.2
Z
`;

export const SmallHexagon = props => <Hexagon {...props} type="endpoint" />;
export const LargeHexagon = props => <Hexagon {...props} type="platform" />;
export const WideHexagon = props => <Hexagon {...props} type="agent" />;

/**
 * Just a path element to the hexagon. Should be inserted into `svg` DOM element
 */
function Hexagon({ active, className, disabled, fill, invalid, shadow, type }) {
  let d;

  switch (type) {
    case 'endpoint':
      d = ENDPOINT_D;
      break;
    case 'platform':
      d = PLATFORM_D;
      break;
    case 'agent':
      d = AGENT_D;
      break;
    default:
      throw new Error(`No such hexagon type specified: ${type}`);
  }

  return (
    <Path
      active={active}
      className={className}
      disabled={disabled}
      fill={fill}
      invalid={invalid}
      shadow={shadow}
      d={d} // eslint-disable-line react/jsx-sort-props
    />
  );
}
Hexagon.propTypes = {
  active: PropTypes.bool,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  fill: PropTypes.oneOf([
    'blue',
    'gray',
    'lightGray',
    'mediumGray',
    'pink',
    'primaryBlue',
    'red',
    'white',
  ]),
  invalid: PropTypes.bool,
  shadow: PropTypes.bool,
  type: PropTypes.oneOf(['endpoint', 'platform', 'agent']).isRequired,
};
Hexagon.defaultProps = {
  active: false,
  className: '',
  disabled: false,
  fill: 'white',
  invalid: false,
  shadow: true,
};

const active = props =>
  props.active &&
  css`
    stroke: ${theme.colors.lightBlue};
    stroke-width: 3px;
  `;

const disabled = props =>
  props.disabled &&
  css`
    cursor: disabled;
    stroke: ${theme.colors.darkGray};
    stroke-width: 2px;
    filter: none;
  `;

const invalid = props =>
  props.invalid &&
  css`
    stroke: ${theme.colors.unhealthyColor};
    stroke-width: 2px;
    filter: none;
  `;

const fill = props =>
  !_.isNil(props.fill) &&
  css`
    fill: ${theme.colors[`${props.fill}HexagonFill`]} !important;
  `;

const shadow = props => props.shadow && blockStyle;

const Path = styled.path`
  ${invalid}
  ${disabled}
  ${active}
  ${fill}
  ${shadow}
`;
