import Alerts from 'utils/alerts';

export const COLLECTOR_TIMEOUT_MS = 120000;

export const Status = {
  CANCELED: -3,
  EXCEPTION: -2,
  FAILED: -1,
  CREATED: 0,
  SENT: 1,
  COMPLETE: 2,
};

const watchTask = function (collectorId, taskId, callbacks) {
  const interval = setInterval(() => {
    $.get(`/collectors/${collectorId}/tasks/${taskId}`).then(task => {
      if (task.status === Status.CREATED) {
        if (callbacks.created) {
          callbacks.created(task);
        }
      } else if (task.status === Status.SENT) {
        if (callbacks.sent) {
          callbacks.sent(task);
        }
      } else if (task.status === Status.COMPLETE) {
        clearTimeout(timeoutID);
        clearInterval(interval);
        if (callbacks.complete) {
          callbacks.complete(task);
        }
      } else if (task.status === Status.FAILED) {
        clearTimeout(timeoutID);
        clearInterval(interval);
        if (callbacks.failed) {
          callbacks.failed(task);
        }
      } else if (task.status === Status.EXCEPTION) {
        clearTimeout(timeoutID);
        clearInterval(interval);
        if (callbacks.exception) {
          callbacks.exception(task);
        }
      } else if (task.status === Status.CANCELED) {
        clearTimeout(timeoutID);
        clearInterval(interval);
        if (callbacks.canceled) {
          callbacks.canceled(task);
        }
      } else {
        clearTimeout(timeoutID);
        Alerts.error(
          'An unexpected error occurred. If the problem persists, let us know through the Chat Button in the bottom corner.',
        );
      }
    });
  }, 300);

  // Automatically cancel task after 120s - matches TestConnectionTaskRunner in collector, plus gives extra time
  const timeoutID = setTimeout(() => {
    clearInterval(interval);
    if (callbacks.timeout) {
      callbacks.timeout(interval);
    }
  }, COLLECTOR_TIMEOUT_MS);
};

export default watchTask;
