import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'react-emotion';
import { theme } from 'utils/styles';

import { blockStyle } from './common';
import SVGText from './SVGText';

export const TooltipHeader = ({ children, width, x, y }) => (
  <SVGText className={headerClass} width={width} x={x} y={y}>
    {children}
  </SVGText>
);

TooltipHeader.propTypes = {
  children: PropTypes.node.isRequired,
  width: PropTypes.number.isRequired,
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
};

export const TooltipLabel = ({ children, width, x, y }) => (
  <SVGText className={labelClass} width={width} x={x} y={y}>
    {children}
  </SVGText>
);

TooltipLabel.propTypes = {
  children: PropTypes.node.isRequired,
  width: PropTypes.number.isRequired,
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
};

const ARROW_TYPES = {
  bottom: {
    translateY: height => height,
    arrowPoints: '23,21 14,0 32,0',
    gagX: 10,
    gagY: -5,
    transparentGagX: 0,
    transparentGagY: -50,
  },
  top: {
    translateY: () => 0,
    arrowPoints: '25,-26 14,0 36,0',
    gagX: 14,
    gagY: 0,
    transparentGagX: 0,
    transparentGagY: -65,
  },
};

const Tooltip = ({ arrow, children, height, status, transform, width }) => (
  <Container transform={transform}>
    <rect height={height} width={width} />

    <rect className={LABEL_CLASSES[status]} height={height} width={6} x={0} y={0} />

    {children}

    <g transform={`translate(0, ${ARROW_TYPES[arrow].translateY(height)})`}>
      <Arrow points={ARROW_TYPES[arrow].arrowPoints} />
      <Gag
        height="5"
        width="25"
        x={ARROW_TYPES[arrow].gagX}
        y={ARROW_TYPES[arrow].gagY}
      />
      <TransparentGag
        height="65"
        width="85"
        x={ARROW_TYPES[arrow].transparentGagX}
        y={ARROW_TYPES[arrow].transparentGagY}
      />
    </g>
  </Container>
);

Tooltip.propTypes = {
  arrow: PropTypes.oneOf(['top', 'bottom']).isRequired,
  children: PropTypes.node.isRequired,
  height: PropTypes.number.isRequired,
  status: PropTypes.oneOf(['disabled', 'healthy', 'unhealthy', undefined]),
  transform: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
};
Tooltip.defaultProps = {
  status: undefined,
};

export default Tooltip;

const headerClass = css`
  fill: black;
  font-weight: 600;
`;

const labelClass = css`
  fill: ${theme.colors.darkGray};
  font-size: 14px;
`;

const Container = styled.g`
  ${blockStyle}
`;

const healthyLabel = css`
  fill: ${theme.colors.healthyColor};
`;

const unhealthyLabel = css`
  fill: ${theme.colors.unhealthyColor};
`;

const disabledLabel = css`
  fill: ${theme.colors.darkGray};
`;

const LABEL_CLASSES = {
  disabled: disabledLabel,
  healthy: healthyLabel,
  unhealthy: unhealthyLabel,
};

const Arrow = styled.polygon`
  ${blockStyle};
`;

// To remove blockStyle effect from arrow back side
const Gag = styled.rect`
  fill: white;
  filter: none;
`;

// To make place near arrow clickable
const TransparentGag = styled.rect`
  fill: red;
  fill-opacity: 0;
  filter: none;
`;
