/* eslint-disable import/prefer-default-export */

// Import required files for all bundles to use
import ReactRailsUJS from 'react_ujs';
import $ from 'jquery';
import Rails from '@rails/ujs';
import Turbolinks from 'turbolinks';

import './vendor/jquery_deparam';

/**
 * Initialize the pack, by starting Turbolinks & ReactRails and loading the required
 * components context.
 *
 * @param {any} requireContext The require context to load components from.
 */
export function initialize(requireContext) {
  Turbolinks.start();
  window.Turbolinks = Turbolinks;
  ReactRailsUJS.detectEvents();
  ReactRailsUJS.useContext(requireContext);

  $(document).on('turbolinks:load', () => {
    // eslint-disable-next-line
    if (!window._rails_loaded) {
      Rails.start();
    }
    window.$ = $;
  });
}
