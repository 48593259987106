import toastr from 'toastr';

toastr.options = {
  closeButton: false,
  newestOnTop: false,
  progressBar: false,
  positionClass: 'toast-top-center',
  preventDuplicates: false,
  onclick: null,
  showDuration: 300,
  hideDuration: 1_000,
  timeOut: 5_000,
  extendedTimeOut: 1_000,
  showEasing: 'swing',
  hideEasing: 'linear',
  showMethod: 'slideDown',
  hideMethod: 'slideUp',
};

/**
 * Create an alert with red text
 * @param text {string} Text to alert with
 */
function error(text) {
  return toastr.error(text);
}

/**
 * Create an alert with blue text
 * @param text {string} Text to alert with
 */
function message(text) {
  return toastr.info(text);
}

/**
 * Create an alert with green text
 * @param text {string} Text to alert with
 */
function success(text) {
  return toastr.success(text);
}

/**
 * Create an alert with orange text
 * @param text {string} Text to alert with
 */
function warning(text) {
  return toastr.warning(text);
}

/**
 * Create an event listener to fire the success alert on the `turbolinks:load` event
 */
function successAfterRedirect(text) {
  document.addEventListener('turbolinks:load', () => success(text), { once: true });
}
/**
 * Create an event listener to fire the error alert on the `turbolinks:load` event
 */
function errorAfterRedirect(text) {
  document.addEventListener('turbolinks:load', () => error(text), { once: true });
}

const Alerts = {
  error,
  errorAfterRedirect,
  message,
  success,
  successAfterRedirect,
  warning,
};

export default Alerts;
